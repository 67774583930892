import React from 'react';
import styled from "styled-components"

import "../css/typography.css"
import "../css/colors.css"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


function Content() {    
    return (
        <ContentContainer>
            <IntroSect className="intro">
                <div className="intro-sect">
                    <h2> To know where we’re going, we have to look back </h2>
                    <p>Activism - understood as engaging in any activity intended to create social or political change - has loomed large in politics in recent years.</p>
                    <p>People organised to support others in their communities. Movements, from Black Lives Matter, to anti-vax protestors and Insulate Britain, took to the streets and online. The term ‘activist’ was used in celebration and as a pejorative, to describe everything from <a href="https://www.standard.co.uk/news/politics/priti-patel-complains-activist-lawyer-criticism-a4539896.html" target="_blank" rel="noreferrer" className="link-hover">lawyers</a>, to <a href="https://blog.bham.ac.uk/business-school/2021/02/02/gamestop-a-new-era-of-shareholder-activism/" target="_blank" rel="noreferrer" className="link-hover">amateur investors</a> and even <a href="https://www.express.co.uk/news/uk/1442385/national-trust-culture-wars-restore-trust-plot-to-oust-hilary-mcgrady-tim-parker-woke-ont" target="_blank" rel="noreferrer" className="link-hover">National Trust members</a>. There were strikes, demonstrations and boycotts, but also major <a href="https://docs.google.com/spreadsheets/d/179hz-OKrfcAr3O0xi_Bfz9yQcK917fbLz-USxPZ3o_4/edit#gid=0" target="_blank" rel="noreferrer" className="link-hover">adaptations</a>: people have done virtual rallies, protested whilst exercising, and shouted from their balconies in solidarity. Politicians’ rhetoric, backlash movements and legislative developments sparked debates about what counts as activism, when it is legitimate, and who is considered an activist.</p>
                    <p>This period will reverberate for years to come. New activists, new causes, new battlegrounds. Activism will continue to play a greater role in public life, from discussions on climate change to trans rights, from the war in Ukraine to the cost of living.</p>
                    <p>Demos seeks to understand how people are and can be brought into politics, and so we spoke to fifteen activists working on issues either created or magnified by the pandemic. They came from big NGOs and small grassroots groups, using campaigns, direct action and much else. To ensure we didn’t give a platform to those who oppose our democratic values, we have not spoken to those promoting violence, misinformation or hatred.</p>
                    <p>We believe these conversations are essential for understanding not just our recent past and still turbulent present, but for getting a glimpse at what the future might hold.</p>
                </div>
                <div className="intro-sect">
                    <h2> A picture of post-pandemic people power </h2>
                    <p>
                        What emerged was a clear sense that activism in this country 
                        has been both tested and rejuvenated by the period we have lived through. 
                        The seven core themes were:
                    </p>
                    <ul>
                        <li>
                            <b>Activists face an array of challenges:</b> these range from financial and operational impacts from social distancing, to tolls on physical and mental health, and an environment often detrimental to activism: harmful legislation and political and media vilification or disinterest.
                        </li>
                        <li>
                            <b>New coalitions have emerged:</b> activists relied on one another more than ever for support and resources, and new relationships were formed between big institutions and grassroots civil society, often for the first time.                        
                        </li>
                        <li>
                            <b>Digitally-enabled, not digitally-focused, activism is the future:</b> online campaigning was relied on more than before but its limitations were brought home, in particular where the social side of activism was undermined. In contrast, tools for collaboration, crowdsourcing and connecting online and offline activities were celebrated, and new practices of digitally-enabled activism emerged or were adopted by new groups.                        
                        </li>
                        <li>
                            <b>Many activists have a new local focus:</b> activists work took on a local focus if it lacked this already, introducing infrastructure for this that is being taken forward. Attention to local action gave members of the public a sense of agency at an isolating time, and the need to connect people’s lived experience or local expertise to political processes was underlined.
                        </li>
                        <li>
                            <b>Activism as care, care as activism:</b> taking action to care for others in society became centre stage during the pandemic, and was the shared desire among activists and others, but perceptions varied nonetheless about when this was activism. Community organising in particular was a blurring point. Despite this, many spoke about successfully managing these differing perceptions and how to take this forward.
                        </li>
                        <li>
                            <b>Activists’ aims and tactics are changing:</b> all had to reassess their tactics, aims and messages, and this will continue as new legislation and public support potentially shifts the goalposts again. Despite an unfolding crisis, many felt they were building capacity, infrastructure and active participation from supporters for the future, particularly at a community level.
                        </li>
                        <li>
                            <b>Activism may have more public support than before:</b> attitudes to activists’ work fluctuated, as issues rapidly came into the public eye, remained, subsided, or went ignored. Despite this, there was more confidence in public support going forward - including from new cohorts - than from policymakers. The links between support and participation, however, were less clear, varying from activist to activist.
                        </li>
                    </ul>
                    <p>
                        Overall, these together point to how forms of activism are changing and finding new participants. In doing so, activism is gaining more proximity to, not distance from, ‘normal’ people. This growing means of political participation should be celebrated as a counterweight to worries of democracy’s collapse.
                    </p>
                    <p>
                        But for this to be recognised, many have to get past the headlines and rhetoric and rethink their assumptions about this part of political life. There has to be a wider recognition of how activism is one of the core things that protects and improves, rather than undermines, democracies. Most of all, there has to be more honesty about the fact that we are all activists when we seek to create social and political change, and so activism is far closer to the work of many than they might perceive it to be.
                    </p>
                </div>
                <div className="intro-sect">
                    <h2> Starting the conversation and what you can do next </h2>
                    <p>
                        Below you can explore the seven themes, in summary and in-depth, 
                        that emerged from the interviews we did. However, we want this to be 
                        the start of a much bigger conversation about the future of activism 
                        in the UK. If you want to be part of this, get in <a href="#author" className="link-hover">touch</a>.
                    </p>
                    <p>
                        We also want this to be about more than words and have included a concluding section which suggests, based on the insights from this research, what the media, funders, public bodies, parliament, local authorities, central government, and civil society as a whole can do next. Through these steps we can create a healthier environment for the best forms of activism to continue to contribute to political life in the UK.
                    </p>
                </div>
            </IntroSect>
            <ThemeSect className="themes">
                <section className="content">
                    <h2>
                        Activists face an array of challenges
                    </h2>
                    <ul className="theme_list">
                        <li>Financial and operational impacts from social distancing</li>
                        <li>Tolls on physical and mental health</li>
                        <li>New legislation that threatens the actions of some activists</li> 
                        <li>Political and media vilification or disinterest</li>
                    </ul>
                    <blockquote>“So much of our culture of protest and organising is done in physical spaces [...] congregation and mobilising and all those kinds of elements”</blockquote>
                    <p>Activists faced a range of challenges through the pandemic. Some of these arose from the period’s particular constraints: new financial and administrative difficulties, and novel operational difficulties created by social distancing.</p>
                    <p>Other challenges centred on the physical and mental health impacts of the pandemic, with the pervasive occupational hazard of burnout among activists heightened, not least by working in and responding to a crisis situation. Knowledge that the challenges they will face to their work as a result of the pandemic for years to come also took its toll on some.</p>
                    <p>A different set of challenges were political in nature - a hostile legislative environment for many; strong political division on their issues amongst wider society; dissent directed at them from new reactionary forces; vilification by some parts of the media and political establishment; and inattention or active attack from Westminster.</p>
                    <h2 className="future">
                        What could this mean for the future? Difficulties inside and out
                    </h2>
                    <p>The challenges facing activists going forward are varied, but it is clear there are changes ahead:</p>
                    <ul className="theme_list">
                        <li>For smaller and newer civil society organisations, the impacts of the pandemic will take longer to overcome, and across the sector, risks of burnout will remain.</li>
                        <li>For activists engaged in demonstration, the Police, Crime, Sentencing and Courts legislation will pose a real threat to their ability to protest, including <a href="https://bills.parliament.uk/publications/42132/documents/484" target="_blank" rel="noreferrer" className="link-hover">provisions</a> to expand the conditions the police can place on demonstrations to those which are integral to their nature, such as causing noise, and creating a statute offence relating to the breaching of these conditions with a maximum sentence of 10 years imprisonment.</li>
                        <li>The impacts of legislation could be wider. Many activists are concerned about the Covert Human Intelligence Sources (Criminal Conduct) Act 2021. The Act <a href="https://www.gov.uk/government/publications/covert-human-intelligence-sources-draft-code-of-practice/covert-human-intelligence-sources-bill-factsheet-accessible-version" target="_blank" rel="noreferrer" className="link-hover">provides</a> an “express power to authorise [Covert Human Intelligence Sources] to participate in conduct which would otherwise constitute a criminal offence.” Concerns have arisen because of the use of covert Government intelligence sources to infiltrate activist groups in the past and the <a href="https://www.theguardian.com/uk-news/2020/oct/28/secrets-and-lies-untangling-the-uk-spy-cops-scandal" target="_blank" rel="noreferrer" className="link-hover">scandals</a> that have emerged from this.</li> 
                        <li>Beyond legislation, the distrust felt by some in civil society to the Government will continue if politicians in general are seen to be stoking anti-activist sentiment through their statements.</li>
                    </ul>
                    <Accordion allowZeroExpanded="true" className="themes-accordion">
                    <AccordionItem>
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                Activists face an array of challenges
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordion-content">
                                While some were able to ride out the financial impacts of the pandemic and others said they in fact saved money, many explained to us how they faced challenges over resources that affected day-to-day operations. Perhaps the most obvious impact on many activists’ work from social distancing was the ability to engage in physical actions. Nathan Oswin of Covid-19 Bereaved Families for Justice explained:
                                <blockquote>“So much of our culture of protest and organising is done in physical spaces, and, you know, congregation and mobilising and all those kinds of elements. And the rules and regulations changing was a big element around, you know, what tactics do you use? What kind of organising mechanics do you choose to engage in? And all the rest. That's probably the biggest thing that's changed at various points during the pandemic and had, yeah, a direct implication for our work.”</blockquote>
                                Social distancing challenged another, central feature of many activists’ work: being close to those whose cause they advocate on behalf of. A knock-on effect of this was to limit understanding of the issues people were facing, and even those who form part of the group they work to support faced more isolation from others. It also struck at a campaign’s ability to bring those same people into the room to advocate for themselves. Tracey Herrington is Manager at Thrive Teesside, a grassroots organisation in Teesside which advocates for the voice of those with lived experience of poverty to be included in decision-making processes. As she put it:
                                <blockquote>“Generally, we were able to maintain [a connection to those who our message was about], because we have good networks of partners and other stakeholders that we work with. Generally, we could maintain that. Where we lost a little bit was, you know, having lived experience - whatever terminology you want to use: people with insight of the issue - around the table. We did have some, we just didn’t have as many, and that matters as we have to have a mandate for what we're doing.”</blockquote>
                                The impacts of social distancing were organisational too, for example, undermining the ability to identify future leaders. Neil Moore, an organiser for Unite Hospitality explained:
                                <blockquote>“We were trying to manage, you know, how we educated and built up reps, and built networks during that period. And I think that was one of the key difficulties, because how we would have identified new leaders [normally] would have been to have in-person meetings [...] [and] almost naturally you’d go up and speak to somebody and encourage them to get involved.”</blockquote>
                                As for many workers during the pandemic, Covid-19 itself was an ever-present risk to their ability to work. So too were the mental health impacts of the pandemic. Burnout from stress and exhaustion, an endemic problem in many parts of civil society, came through as a pervasive challenge for those we spoke to: from being so bad that people were unable to organise strikes, to reducing people’s ability to campaign creatively. Matt Clifton from bemix, a social enterprise supporting people with learning difficulties and/or autism to achieve equality, described how:
                                <blockquote>“The trouble with big [campaign] moments, where there’s a concentration of energy, is the risk that it then dissipates afterwards. There are few signs of the renewal of that same kind of energy and intensity. I think coupled with that, there's a reduction in the creativity that you need as well, the ideas that go into thinking, “Well, how can we cut through? How can we bring what's hidden into the open when this is such a hidden and desperate issue for people?”</blockquote>
                                Working in a crisis situation introduced specific pressures too, not least raising expectations from those looking to activists for support amidst rapidly changing legal, political, economic and public health circumstances. For newer organisations, this environment also meant they were being pulled in directions that took them away from other work. Katrina Ffrench, Founder & Director of UNJUST, a non-profit organisation committed to reimagining policing and the criminal justice system, explained how:
                                <blockquote>“It's been quite intense in that the area that we focus on, racial equality, has been in the spotlight of healthcare and policing or law enforcement, so that intersect. So we're really busy. And in that busyness, there hasn't been as much time as in previous times to do the networking, and the understanding around issues that weren't Covid related. So new things emerged, some things went to the side, and some things were exacerbated.”</blockquote>
                                The Government’s Police, Crime, Sentencing and Courts legislation, which amongst other things targets people’s freedom to protest, was of particular importance to those we spoke to given the threat it poses to the physical actions some engage in. Jake Johnson is from the Manchester branch of ACORN, a mass membership organisation and network of low-income people organising for a fairer deal in their communities. As he told me:
                                <blockquote>“[Amongst the Kill the Bill coalition] the common theme throughout is that though everyone uses different tactics and to different extents to work towards their own targets, each group still depends on having that option to get on the street and be annoying and make noise and be a nuisance.”</blockquote>
                                Portrayal of activists’ work in a negative lens by the media and by politicians was a prominent issue raised in conversations. Some highlighted how this predated the pandemic but carried over as their causes got new attention. Tracey from Thrive Teesside pointed to how the media’s acceptance of certain topics swayed this tremendously:
                                <blockquote>“There were some campaigns where people did get on board, you know, like the digital divide, that was quite well respected. Maybe they wouldn't agree with our outcomes - you know, we said one of our long term aims was to have free wi-fi for all and they’d say “Oh, why that?” -  but if we [just] talked about how there's a lot of people that are not connected and what can we do to help them then that would be accepted.”</blockquote>
                                Others pointed to the harm this causes for creating good policy, by limiting the ability of policymakers to hear perspectives on, or to understand, what life is like for people during the pandemic and beyond. As one person we spoke to described:
                                <blockquote>“It has been quite difficult - outside of working with members of the House of Lords - MPs I don't think have been incredibly helpful over the last year or so when it comes to civil rights. Not all of them: some have been involved in Kill the Bill, so I won't say all of them. But in the main, they're not receptive to the sort of conversations that civil society wants to have, or the engagement, or where we think democracy is going and how they can better engage with people impacted by their policies.”</blockquote>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>        
                </section>
                <section className="content">
                    <h2>
                        New coalitions have emerged
                    </h2>
                    <ul className="theme_list">
                        <li>Activists relied on one another more than ever for support and resources.</li>
                        <li>New relationships were formed between big institutions and grassroots civil society, often for the first time.</li>
                    </ul>
                    <blockquote>“[I’m in civil society] WhatsApp groups, a lot of which are just broadcast only, which is great to celebrate other people's successes. And so that made me feel as though I am part of a much broader movement”</blockquote>
                    <p>The experience of creating and enhancing coalitions for change was one of the most prominent themes to emerge in discussion with activists. People recognised they were part of an ecology of solutions to the same cause (or related ones), and saw the alliances they were a part of as something they could rely on for resources and moral support.</p>
                    <p>Larger organisations found a greater focus on connecting up to smaller and grassroots groups, and in turn these were in closer contact with bigger institutions for their support than before.</p>
                    <p>Across the board, people discussed working with partners very different to those they had previously connected with. Though the legislative environment was seen as a hostile one for activism, many raised how the same legislation acted as an anchor around which coalitions could form and rally against.</p>
                    <h2 className="future">
                        What could this mean for the future? Connection is core
                    </h2>
                    <p>Given the positivity people felt towards working in coalition during the pandemic, it seems likely that this trend will only continue going forward. In particular:</p>
                    <ul className="theme_list">
                        <li>The coalitions and broader interest in coalition-building that emerged from the pandemic will likely continue around issues which will remain high on the political agenda indefinitely, such as climate change.</li>
                        <li>The pursuit of unconventional partnering could grow too, particularly where this is motivated by other developments. For example, larger organisations working with grassroots activists to bring more attention to lived experience, or geographically dispersed parts of civil society collaborating more readily online.</li>
                    </ul>
                    <Accordion allowZeroExpanded="true" className="themes-accordion">
                    <AccordionItem>
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                New coalitions have emerged
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordion-content">
                                At a time of deep social fragmentation, the desire and the need to coalesce became stronger than ever. This was strongly felt by those we spoke to, who described a period in which the breadth of civil society had to lean on one another for support, resources and to get a full picture of the challenges people were facing. This process meant a deeper recognition that all across civil society are part of movements, part of ecologies of solutions to the same or overlapping causes. Robert Palmer is Executive Director of Tax Justice UK, a campaigning and advocacy organisation that works to ensure that everyone in the UK benefits from a fair and effective tax system. He described the experience of coalition during the pandemic this way:
                                <blockquote>“[I’m in New Economy Organisers Network’s] WhatsApp groups, a lot of which are just broadcast only, which is great to celebrate other people's successes. And so that made me feel as though I am part of a much broader movement that isn't just about wealth, but it's about a broader range of economic and social justice issues that all fit in together.”</blockquote>
                                For some it was an experience that confirmed an existing view of the importance of movement solidarity. Neil Moore, an organiser for Unite Hospitality, highlighted that:
                                <blockquote>“If there's significant movement in the private sector, and industrial action, it can give confidence to unorganised sectors like hospitality to take action. But it might also give confidence to the public sector, particularly, to take action, because there's a desire to take action amongst health workers that I've been speaking to, but there's a nervousness about how it looks in the middle of a pandemic. And also just given how over-demand and understaffed they are, would you even be able to manage an effective strike and emergency cover at the same time.”</blockquote>
                                There was also a clear recognition by some larger organisations that they needed to reach out to focus more on connecting up to smaller and grassroots activist groups, since without this they would be in the dark about the rapid changes. Ondine Sherwood, co-founder of LongCovidSOS, a UK-based campaign for recognition of the debilitating effects of long-term Covid-19, explained:
                                <blockquote>“The impact that our activities had, was that it drew the attention of the World Health Organisation. Maria Van Kerkhove [Technical Lead on Covid-19 at the WHO] actually contacted us on Twitter, because they were trying to find out what was going on. They didn't know much about Long Covid and they realised that they had to find out as quickly as possible.”</blockquote>
                                Very often, people told us about working with partners who were out of the ordinary for them: as one person put it, even where they might previously have disagreed with the partners in question, “in this context, they’re my peers and my comrades”. Driving this was a sense of people working together to simply address an urgent need, rather than due to a shared worldview or pre-existing relationships. Tracey Herrington is Manager at Thrive Teesside, a grassroots organisation in Teesside which advocates for the voice of those with lived experience of poverty to be included in decision-making processes. As she put it:
                                <blockquote>“We did a big piece of work around debt deductions from benefits, you know, and we tied in with like, Trussell Trust, Stepchange, we even reached out to the Centre for Social Justice with their report. So we just became a bit more smarter in what we were doing. And I think, to be fair, there are lessons to be learned and carried on forward aren’t there? So it forced us into a way of work where we would reach out to non-traditional partners. [...] we were forced into a position that lends itself to opportunities in the future.“</blockquote>
                                Many we spoke to who explained how they saw the legislative environment as hostile one to their activism or to their own causes going forward, nonetheless highlighted how the same legislation had acted as an anchor around which coalitions formed which could offer people support. Katrina Ffrench, Founder & Director of UNJUST, a non-profit organisation committed to reimagining policing and the criminal justice system, described one of these coalitions:
                                <blockquote>“It’s been invaluable. I wouldn't have been able to monitor the developments of the Bill if it hadn't been for partners at Amnesty and Liberty who arranged weekly sessions, where we and others would give updates on the Bill and who we'd spoken to and what had been going on. And that's been an opportunity for me to know where the Bill was at, who said what, and I don’t have to do anything other than attend a meeting, which is only one hour in comparison to the charting of that which could take hours.”</blockquote>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>                         
                </section>
                <section className="content">
                    <h2>
                        Digitally-enabled, not digitally-focused, activism is the future
                    </h2>
                    <ul className="theme_list">
                        <li>Online campaigning was relied on more than before but its limitations were brought home, in particular where the social side of activism was undermined.</li>
                        <li>Tools for collaboration, crowdsourcing and connecting online and offline activities were celebrated.</li>
                        <li>New practices of digitally-enabled activism emerged or were adopted by new groups.</li>
                    </ul>
                    <blockquote>“To what extent is it important to go back into the real world? I think it is, but one thing that we learned is that having a meeting on Zoom is incredibly accessible for people”</blockquote>
                    <p>The pandemic brought in its wake for many of us a tremendous digitalisation of everyday life, and activism was no less affected. People discussed how digital campaigning was relied on more than before, though its limitations were keenly brought home, undercutting some of the prevailing optimism for social media activism in previous years.</p>
                    <p>Digital tools for collaboration, for crowdsourcing experiences, and for connecting online and offline activities were more roundly celebrated, with intriguing new practices of digitally-enabled or supported activism emerging, and/or rolling out to new groups.</p>
                    <p>This mixed attitude to a digital by default period of activism meant some features were seen as welcome, permanent changes whereas others were seen as temporary accommodations that should be or are in the process of being reverted from. The biggest reasons for scepticism about digital changes were the challenges of inclusion, the online harms they can introduce, and the way in which they undercut the personal, social aspects of activism that are hard to replicate online.</p>
                    <h2 className="future">
                        What could this mean for the future? Accessibility is key
                    </h2>
                    <p>While many of the digital impacts of the pandemic across society seem here to stay, from the activists we spoke to it is clear that the picture is mixed:</p>
                    <ul className="theme_list">
                        <li>We aren’t likely to see digital campaigning become the default for every organisation. But the pandemic has shown us that making activities accessible can and should be prioritised: the movements which are most likely to thrive are those which are able to adapt to the needs of different groups of people.</li>
                        <li>Digital technology has lowered the barriers to participation during the pandemic - facilitating more casual interactions and rapid-response collaboration at the tap of a screen. It’s also opened up a huge range of possibilities for how movements can grow - no longer seen as merely a tool to help amplify campaign messages, the use of tech to help crowdsource experiences, organising the sharing of resources, and co-ordinate offline action is unlikely to wane.</li>
                    </ul>
                    <Accordion allowZeroExpanded="true" className="themes-accordion">
                    <AccordionItem>
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                Digitally-enabled, not digitally-focused, activism is the future
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordion-content">
                                The Web, social media and smartphones have fundamentally altered the work of many activists the world over in previous decades, so the immense digitalisation of everyday life brought by the pandemic was always going to have an effect too. The intense switch to the online world brought out just what social change can and cannot be achieved through technology alone. Those we spoke to discussed how digital campaigning was relied on more than before, though its limitations were keenly brought home, undercutting some of the prevailing optimism for social media activism in previous years. Mattey Mitchell, Health Campaigns Officer at Friends Families and Travellers, a national charity that works on behalf of all Gypsies, Travellers and Roma, put it this way:
                                <blockquote>“Has the digital space been as effective? I don't know if it's as effective as being in a room with somebody or being right in front of somebody or making that kind of impact. But, that definitely must be the number one change in this space, where you’re trying to communicate how you feel over what is essentially, let's face it, not a great communication tool; the internet, for writing and reading people's thoughts and opinions, it's so subjective.”</blockquote>
                                Looking forward, others similarly calibrated the value of technology to achieving social change. Neil Moore, an organiser for Unite Hospitality explained how:
                                <blockquote>“While there was an increase in the quantity of work, it was much more difficult to build real connections with workers, which is often key to successful campaigns. And I really think it emphasises that, whilst we really need to be using technology as a tool in, like, modern organising and democratic participation, it's definitely not a substitute for that face-to-face contact.”</blockquote>
                                In contrast to the greater reliance on social media campaigning, messaging and organising tools were more roundly celebrated. These centred collaboration at a time when coalition was needed, crowdsourcing of experiences when people could not share these in person, and even allowed for the connection of online and offline activities in some cases, particularly around community organising and more local action. Hannah Dewhirst is a campaigner at EveryDoctor, a doctor-led campaign organisation fighting for a better NHS which has a large Facebook community and various means of communicating with members. She described the various benefits of their digital approach during the pandemic:
                                <blockquote>“[Healthcare workers] were getting mixed messages from Government, and they were looking for a sense of community. We are a campaign organisation, but also a membership organisation and a community for our members, so they can come together and feel heard. So I feel the ability of EveryDoctor to have that connection with frontline practitioners and really get what is going on on the ground in terms of information out into the press, and into briefings with MPs, that all exploded in a positive way. The effect of the pandemic and everything going online was very useful in that regard. Obviously prior to that with MP briefings, well, you had to book a room at Westminster and all that jazz.”</blockquote>
                                Another common experience was the newfound accessibility (for those not digitally excluded) to activists’ work and support for many due to the switch to online. Aidan Harper, an organiser for the Independent Workers’ Union of Great Britain, a grassroots member-led union, emphasised how:
                                <blockquote>“[Online meetings can be] much more accessible if people have kids and stuff like that. So sometimes turnout is higher. And then also there are things that you can do, like daytime meetings during work when you haven’t got a manager looking over your shoulder. People have that opportunity to be a bit more autonomous with their time and not have that fear of having or organising trade union conversations in their workplace.”</blockquote>
                                Likewise, Jake Johnson from the Manchester branch of ACORN, a mass membership organisation and network of low-income people organising for a fairer deal in their communities, explained:
                                <blockquote>“To what extent is it important to go back into the real world? I think it is, but one thing that we learned is that having a meeting on Zoom is incredibly accessible for people in a way that a church or in the centre of town isn't. When you think about people finishing work, people with families, people with disabilities, people living in areas in the city where the public transport connections are crap. All of this stuff is a factor. And when you arrange a meeting on Zoom, that stuff to a large extent becomes less of a factor.”</blockquote>
                                Looking forward, there are undoubtedly intriguing new practices of digitally-enabled or supported activism that have emerged during the pandemic, and whole new groups whose work has been transformed by their new or vastly increased use of digital tools. Sada Abdalla is a community organiser who worked during the pandemic with Racial Justice Network, a West Yorkshire network of individuals, communities and organisations promoting racial justice. She explained how her ability to connect people locally with information they need has been revolutionised by a growth in the use of messaging apps:
                                <blockquote>“In the community, sometimes you get these people, they don't know where to get what they need, you know? So now, I feel like all the people that are in that [WhatsApp] group, they know that if they have any question or any thing that they need, they can ask me, “Sada, can I get this?” Or, “I have this problem, where can I get information about this?” So it's like having someone, having a shoulder in the community, you know?</blockquote>
                                <blockquote>[...] And sometimes I don't know myself, but if they ask me then I will say “You know what, I don't know, but I can find someone who knows about it. Other groups [I’m in] have people from different professional areas - maybe from the public health or someone from the council - I will ask anyone about it, then we can get information for them. So it’s like having someone who can ask a question for others. So that's the great thing. I will say, it's a great thing that happened in the pandemic years. Many people now know where to go, they know where to ask.”</blockquote>
                                As society became effectively digital by default, however, not all changes were seen as welcome and desirable for the long-term. The biggest concerns were from digital exclusion, the cost of online harms, and the way in which a reliance on the online world undercut the personal, physical, social aspects of activism that are hard to replicate. Ondine Sherwood, co-founder of LongCovidSOS, a UK-based campaign for recognition of the debilitating effects of long-term Covid-19, highlighted that:
                                <blockquote>“Not everyone with Long Covid is online; I'm in a small group. Our job now is to try and help people who are not getting that. Because they're not online for whatever demographic reasons, for age reasons, for cultural reasons, or through choice, they're not on online support groups. And some of them may not really understand the condition very well, because, you know, not everybody is scrolling through the news. So the online nature of this doesn't help everybody.”</blockquote>
                                Aidan from the Independent Workers’ Union of Great Britain explained how “building collective, relational power” is core to their work, something made acutely difficult by the circumstances:  
                                <blockquote>“You don't get to have a drink with them, share a meal or share a funny story with them. Like, my relationship with people who do this stuff is actually from everything around the edges of what happens to be why we initially came together [...] the thing that builds up and strengthens those relationships are all the other little chats around [the activism].”</blockquote>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>     
                </section>
                <section className="content">
                    <h2>
                        Local focus
                    </h2>
                    <ul className="theme_list">
                        <li>Activists work took on a local focus if it lacked this already, introducing infrastructure for this that is being taken forward.</li>
                        <li>Attention to local action gave members of the public a sense of agency at an isolating time.</li>
                        <li>The need to connect people’s lived experience or local expertise to political processes was underlined.</li>
                    </ul>
                    <blockquote>“[People feel] like they've been forgotten about by particularly large national level organisations and campaigning bodies as well, and so we need to reset that”</blockquote>
                    <p>A lot of commentary on how society responded to the pandemic has drawn attention to the renewed role of local life: new connections to neighbours, support amongst communities, and the ability of devolved and grassroots institutions to act swiftly and in a more targeted way than central Government were able to the intricacies of local demand.</p>
                    <p>The activists we spoke to highlighted how much their work took on a local dimension if it did not already. The members of the public they worked with also communicated the benefits of this to them at a time when they otherwise felt isolated and disempowered. Where attention to local action had been neglected by organisations before the pandemic, they discussed how they were building new infrastructure to connect more to people locally across the UK.</p>
                    <p>Those we spoke to also connected the focus on local life to the larger political context. In particular, they brought out how people’s lived experience and expertise about local issues - something our interviewees were close to through their activism - was going ignored by governments. Though not seen as a new problem, activists’ experience of this during the pandemic felt more acute and reflective of a growing need to bring lived experience more into political processes.</p>
                    <h2 className="future">
                        What could this mean for the future? A grassroots resurgence
                    </h2>
                    <p>A shift to a local focus may have far reaching effects on activism and civil society more generally:</p>
                    <ul className="theme_list">
                        <li>We may see new attention from organisations and movements which have worked less closely at the grassroots in the past to building capability, or at least forming connections, at this level of political engagement. In doing so, this may encourage more local campaigning, most likely around issues where this could increase anyway, for example around climate change.</li>
                        <li>Larger civil society groups may also experiment with more networked forms of activism over traditional, centralised approaches to campaigning.</li>
                        <li>More unpredictable is whether local governments that have cooperated with local activists and community organisers during the pandemic will continue to do so; for example, with the former acting as a trusted mediator in the provision of certain services or as a source of insight on determining community demands.</li>
                        <li>If civil society continues its current elevation of lived experience, the connections between this and activism may coincide more often; for example, more collaborations between charities advocating for policy change relating to poverty and activists with experiences of poverty themselves.</li>
                    </ul>
                    <Accordion allowZeroExpanded="true" className="themes-accordion">
                    <AccordionItem>
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                Many activists have a new local focus
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordion-content">
                                <p>In so much discussion about responses to the pandemic attention has been drawn to the renewed role of local life: our connections to neighbours, support amongst communities, and the ability of devolved and grassroots institutions to act swiftly and in a more targeted way than central Government were able to the intricacies of local demand. For many activists this was not a new development: they had long recognised the value of locally-rooted, community action and pointed to the way that it was instead mainstream perspectives that were in fact catching up with this. Shiri Shalmy is co-founder of Cooperation Town, a new network of community-led food co-ops. As she put it, this attention to community “entered some kind of liberal imagination” for the first time despite being familiar to many others.</p>
                                <p>Similarly, those activists used to working at this level understood the benefits of this for the public during the pandemic, as they saw people feel a sense of agency over their lives in a period when they otherwise felt isolated and disempowered. Jake Johnson is from the Manchester branch of ACORN, a mass membership organisation and network of low-income people organising for a fairer deal in their communities. As he put it, this approach is about:</p>
                                <blockquote>“Chatting to people about what they hate, what they're angry about in their communities, what they want to see changed, and trying to sort of suggest at least a first step in a solution in a way that emphasises their autonomy [...] [For example] the sort of strategy for approaching people and getting them to support our campaign for landlord licensing was to get people aware for a start, because most hadn't been told. Even though the council said there'd been a consultation, most people hadn’t been told.”</blockquote>
                                For larger organisations and parts of civil society which have traditionally taken a more Westminster focus, there was a realisation that they needed to connect more to a different scale of action. Alasdair Roxburgh, previously Director of Communities and Networks at Friends of the Earth during the pandemic and now at Mind, articulated how:
                                <blockquote>“[A Westminster focus] negates all the opportunities that present in devolved levels of governments, but it also negates the realities for communities elsewhere around the country. And in many ways, there's an echo of, like, the Red Wall seat narrative of going well, “we just feel forgotten about”. It was not only by politicians, but it also feels like they've been forgotten about by particularly large national level organisations and campaigning bodies as well, and so we need to reset that.”</blockquote>
                                A striking feature of this theme was also the way in which people connected a new focus on local life, right down to individual experience of challenges during the pandemic and beyond, to larger political processes. Tracey Herrington, Manager at Thrive Teesside, a grassroots organisation in Teesside which advocates for the voice of those with lived experience of poverty to be included in decision-making processes, described how:
                                <blockquote>“My optimism lies in, you know, the recognition that there's a certain area of expertise that comes from experience, that is becoming more valued and able to contribute to decisions that are being made.”</blockquote>
                                Despite this, there was a risk that the expertise of people at a local level could continue to be ignored. As Tracey added:
                                <blockquote>“There is knowledge and insight held within communities and it is valuable. If you put your nice head on, I guess people who work in the civil service, or in local authorities, or in Government, at times the intention’s probably honourable but it’s about things they don't fully understand what that impact will have. So I think [they have] got to work on that premise, sometimes more, you know: people have this knowledge.”</blockquote>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>     
                </section>
                <section className="content">
                    <h2>
                        Activism as care, care as activism
                    </h2>
                    <ul className="theme_list">
                        <li>Taking action to care for others in society became centre stage during the pandemic, but perceptions varied about when this was activism.</li>
                        <li>Community organising, in particular, was a blurring point.</li>
                        <li>Many activists felt they successfully managed these differing perceptions among those they worked with and discussed how to take this forward.</li>
                    </ul>
                    <blockquote>“People [...] started to see activism less as this niche sort of thing for people who have too much time on their hands, and [...] associated it more with people that they know, and associated more with just having care for others in a difficult situation”</blockquote>
                    <p>Analysing the experiences of activists during the pandemic was always going to bring out an inherent tension of social action, from volunteering, to organising, to campaigning: when is and when isn’t something ‘activism’?</p>
                    <p>This played out in a number of ways in our conversations. One way this came through was in activists’ perceptions of whether or not people who were acting to help others in response to the pandemic, for example through mutual aid, understood the circumstances of those they were trying to help. For certain issues, there was a more unambiguous sense that there was new found, widespread public awareness of how these were symptoms of broader challenges.</p>
                    <p>Another dimension concerned the centrality of taking action to help others. For some who got involved with activists’ work, taking action was not seen as having any political association: their involvement being purely about wanting to care for others rather than effect wider change. At the same time, those we spoke to brought out how these same actions were at times politicising people to view what they were doing in a broader light. Resonating with the theme of a local focus, some discussed how attention to local life and organising in communities was a particular blurring point for judgments of what was or wasn’t activism.</p>
                    <p>People also discussed how tensions that could arise from these differing perceptions were managed during the pandemic when cooperating with others who viewed activities differently (or would have done so in another context than the pandemic), and the value of this to working with others on issues going forward.</p>
                    <h2 className="future">
                        What could this mean for the future? The gap between activists and the public has narrowed
                    </h2>
                    <p>The distance between people and activism has narrowed as the pandemic brought politics deep into many people’s lives:</p>
                    <ul className="theme_list">
                        <li>Where certain topics continue to feel close to people’s lives, there will be, amongst those affected, many new activists. In particular, this could be true where the cause is a long-term effect of the pandemic, such as unemployment, vaccination hesitancy, Long Covid or bereavement.</li>
                        <li>People’s willingness to engage in activism more generally may have shifted, if the pandemic’s focus on taking urgent action has changed political outlooks. Similarly, the focus on supporting others locally may encourage more community activism.</li>
                        <li>Solidarity, rather than charity, may have become a more motivating factor for some to engage in social action in all forms where they felt common cause with others during the pandemic; in particular, this could see a continued rise in trade union support.</li>
                    </ul>
                    <Accordion allowZeroExpanded="true" className="themes-accordion">
                    <AccordionItem>
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                Activism as care, care as activism
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordion-content">        
                                <p>What counts as activism is often contested, not least because the idea carries a political weight to it that other kinds of social action, from volunteering to charity, do not. Often, many distance themselves from the political implications of their acts in support of others. Yet, the pandemic has reached into all our lives and placed at our feet questions with unavoidable political relevance and connection to others: do I wear a mask? Do I get a vaccine? Is my employer thinking about my safety? Can I help others locally who are more vulnerable?</p>
                                <p>Given this period, the distance some might be able to place between themselves and activism has narrowed, a blurring of political and non-political action that was prominent in our conversations with activists. One way this came through was in their perceptions of whether or not people who were acting to help others in response to the pandemic, for example through mutual aid, understood the circumstances of those who needed help. Shiri Shalmy is co-founder of Cooperation Town, a new network of community-led food co-ops. She described how people’s motivations here could reveal a lack of understanding about who in society faced the greatest challenges, and what supporting them meant beyond the circumstances of the pandemic:</p>
                                <blockquote>“I mean, it's nice that we call it mutual aid, you know, maybe twelve more people have heard of Kropotkin. But it's not mutual aid, it’s charity. And in the nature of charity, you do it on your own terms, right? So once you’re no longer furloughed, or once you got bored of it, you don’t do it. At the beginning of Covid, I remember sitting in a meeting with a mutual aid group [...] and they were like, “we don't understand, can you help us solve this mystery? We have sixty people in our mutual aid group, and only six people want our help. What can we do?” It’s like they were asking, how do we generate more misery so that we could do a nice thing?”</blockquote>
                                In contrast, for some the politics of the moment was not lost on them at all. This came through in discussion of funders, for example, which some activists explained were uncomfortable with the proximity to clearer political connotations. Tracey Herrington, Manager at Thrive Teesside, a grassroots organisation in Teesside which advocates for the voice of those with lived experience of poverty to be included in decision-making processes, described how:
                                <blockquote>“You've got some funders that are keen to support, [...] but you know [others] [...] want a less campaigning aim. And again, I think [their discomfort] is political, isn't it?”</blockquote>
                                Many we spoke to discussed managing the tensions successfully that could arise from these differing perceptions, and the value of this to working with others. Shiri from Cooperation Town illustrated this in connection to a conversation with a local authority:
                                <blockquote>“They were like, “how do we get [to a better situation for local people]?” And I said, “I don't know, read history? Like, you get this through a conflict.” [...] So yeah, organising at the point of conflict. But having, you know, a seat at the table to do it. Otherwise, you’re just at the point of conflict. Trade unions have a seat at the table, you know, like there's a legal framework for trade unions to negotiate on behalf of workers. So, sure, my trade union: we smash things, we occupy things, we occupy stuff, we picket, we disrupt, like, relentlessly. But we also have a seat at the table and others don’t.”</blockquote>
                                The pandemic placed taking action to support others at the centre stage in both politics and wider social life, and so this was a key area in which politics did or did not arise for people. For some who got involved with activists’ work, taking action was not seen as having any political association: their involvement being purely about wanting to care for others rather than effect wider change. Some of the most complicated experiences of this were around the loss of lives to Covid itself. Nathan Oswin from Covid-19 Bereaved Families for Justice explained how, “the vast majority of [our] members are, I wouldn't even say political in the small p, let alone a big P.” At the same time, some who lost loved ones to Covid would experience a politicising process as a result:
                                <blockquote>“It's, you know, people who were… I suppose it's that moment of, you know, when they lost someone [...] within a short turnaround time they became angry at steps that the Government had taken.”</blockquote>
                                Yet, even here this would not mean the adoption of a straightforward newfound ‘activist’ identity. Nathan explained how though some brought a personal anger with the Government with them into Covid-19 Bereaved Families for Justice, they were not all “in the mindset of a campaigner”. For others, there could be such a change but over a longer period. Shiri from Cooperation Town emphasised that their goal is not just about taking immediate, urgent action to care for others but also to start a change that could have political ramifications down the line beyond that need:
                                <blockquote>“The idea is that we build confidence in our neighbourhoods. Food is almost not the main thing: through it we learn to organise with our neighbours. And, you know, when the council then tries to sell the estate to a developer or whatever, we will be organised.”</blockquote>
                                The local dimension to the political impacts of action resonated with those organising in communities especially. Jake Johnson, from the Manchester branch of ACORN, a mass membership organisation and network of low-income people organising for a fairer deal in their communities, put it this way:
                                <blockquote>“I think that people who might have had that impression of people and parties coming around and knocking on their doors and only having a sort of skin-deep interest in their lives [...] Those people started seeing, not just ACORN coming and knocking around or other groups as well, but also their neighbours. And I think, to a certain extent, that had the same sort of principles in action: neighbourhood groups were set up, Facebook groups were set up, people rang around their communities; with Coronavirus support, picking up meals for people, picking up medicines for people.</blockquote>
                                <blockquote>And I think that in that sense [...] people maybe didn't see activism, or started to see activism less as, this niche sort of thing for people who have too much time on their hands, and [...] associated it more with people that they know, and associated more with just having care for others in a difficult situation. And yeah, just kind of looking out for each other more, which I think is, or maybe not always is but should be, at the root of community activism.”</blockquote>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>     
                </section>
                <section className="content">
                    <h2>
                        Activists’ aims and tactics are changing
                    </h2>
                    <ul className="theme_list">
                        <li>The pandemic meant all activists had to reassess their tactics, aims and messages, something new legislation and potentially shifting public support will require them to continue to do.</li>
                        <li>Despite an unfolding crisis, many felt they were building capacity, infrastructure and active participation from supporters for the future, particularly at a community level.</li>
                    </ul>
                    <blockquote>“I feel like I'm seeing the light. I feel like this thing will be big at the end of the tunnel. Something that we didn't even know before.”</blockquote>
                    <p>The pandemic introduced entirely novel challenges for people’s activism, in particular social distancing. Likewise, it hugely affected the issues which were brought into the public eye or onto the political agenda, from health inequalities to workers’ rights. Such changes meant that activists discussed how they were forced to reassess the advantages and disadvantages of their tactics and messages in a more ongoing fashion, and respond to a rapidly changing spectrum of acceptable ideas in the public eye.</p>
                    <p>In an environment where activists were trying to respond to an unfolding crisis, there was at the same time a real sense of building capacity for the future among many. This was influenced by a variety of factors: rapidly created networks and resources, knowledge that legislation would challenge the work of some, changing levels (both positive and negative) of public support, and the new time available to some to reflect on the future of their work.</p>
                    <p>These resulting changes included the building of infrastructure to better coordinate responses to present challenges as well as face future challenges, particular attention on organising in communities, changes to and changed attitudes to particular modes of protest, and a growth of active participation in a cause.</p>
                    <h2 className="future">
                        What could this mean for the future? Active, networked, short-term
                    </h2>
                    <p>The goals of many activists will have changed, and whole new causes will have emerged as new topics are on the agenda, new groups are politicised, and new methods of action are more appropriate. Looking forward, this might mean in particular that:</p>
                    <ul className="theme_list">
                        <li>Civil society as a whole may encourage more active participation in their cause from supporters. Where activists have made gains during the pandemic, more might seek to engage in actions which aim at prevention of the issues they address and construction of political alternatives, rather than focusing on reactive activism.</li>
                        <li>The future of social movements may be more and more a matter of networks than traditional forms, as reliance on online life and a shift to local concerns shifts interest towards different forms of organising and taking action. In particular, the pandemic may have further accelerated the trend of movements which emerge and coalesce around a cause separate from a traditional campaigning body or party.</li>
                        <li>In an increasingly politically turbulent world, the ability of events to upend long-term strategies may encourage more adaptable short-term strategising and action.</li>
                        <li>The methods of activists may change, though this will be due to a variety of factors rather than just the digital focus of the pandemic. Related to this, the public will rightly have higher expectations about the accessibility of campaigning, which may continue.</li>
                    </ul>
                    <Accordion allowZeroExpanded="true" className="themes-accordion">
                    <AccordionItem>
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                Activists’ aims and tactics are changing
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordion-content">                              
                                One of the most peculiar aspects of the pandemic period for activism has been its curtailing of people’s ability to take physical action in the world. It also radically refocused the burning issues in the public eye, from health inequalities to workers’ rights. These upheavals meant many had to continually reassess their aims, tactics and messaging, as public opinion and rules around what actions could be taken by activists developed. Wolfgang Wopperer-Beholz, an activist with Extinction Rebellion, described how these upheavals played out for him:
                                <blockquote>“[The pandemic] changed my perception of which kind of strategising is helpful or adequate in the first place. It was a very clear example of okay, you can sit down, have a month-long process and think about important stuff and come up with good ideas. And then [all of a sudden] it's all for nothing, basically. So it changed the way I would, and did, approach strategy making.”</blockquote>
                                For some, the ascendancy of certain issues and the new changes to people’s daily lives had a tremendous catalysing effect on their messages’ ability to cut through. Neil Moore, an organiser for Unite Hospitality, explained how:
                                <blockquote>“There would have been people that we would come into contact with in 2018-2019, who didn’t join the union until the pandemic. I guess it was a bit of an individual issue behind it, but it was sort of the confirmation of everything [we’d] have been saying. Someone might have thought previously, “My boss is alright, my manager is alright, I don't have issues around this, they sort me out. Yes the pay’s a bit shite, yes, you know, there's sexual harassment that goes on but it's not the company's fault, it’s the customer's fault. And, you know, our question is always, “Well, what happens when the proverbial hits the fan or your manager changes? Does the company really care about you then?” I think very quickly [as the pandemic emerged] workers drew that lesson.”</blockquote>
                                Despite responding to an immediate crisis situation, there was at the same time for many a real sense of building capacity for the future. Sada Abdalla is a community organiser who worked during the pandemic with Racial Justice Network, a West Yorkshire network of individuals, communities and organisations promoting racial justice. She described her greater use of technology through the pandemic to connect people to resources:
                                <blockquote>“I feel like I'm seeing the light. I feel like this thing will be big at the end of the tunnel. Something that we didn't even know before. You know, this service, putting the service into the people’s hands, directly even, using social media. You get people connecting with the services with social media, it is great.”</blockquote>
                                For some their planning for the future was driven by negative developments, especially political pressure. Jake Johnson from the Manchester branch of ACORN, a mass membership organisation and network of low-income people organising for a fairer deal in their communities, described how people were looking ahead to the potential impacts of the Government’s Police, Crime, Sentencing and Courts legislation for example:
                                <blockquote>“One sort of commitment that was made by all the groups that had a presence at the Kill the Bill demonstration in Manchester, was that regardless of what the law says, we're still going to keep doing what we do. There'll be further adaptations there, because we'll start having to consider, what happens if our members get arrested doing something? And what the threat is gonna do to our engagement. Plus, we've got, obviously, a duty of care to our members. But there's certainly no sort of sense that those who are in the union who are happy to continue going out and defying the new laws would stop doing what we do, just because of that.”</blockquote>
                                Other changes were about taking the organisational infrastructure of the pandemic response forward, particularly where this overlapped with doing more community organising. Particularly, though not exclusively, for trade unions, it also meant the growth of encouraging active participation. Aidan Harper, an organiser for the Independent Workers’ Union of Great Britain, a grassroots member-led union, put it this way:
                                <blockquote>“[I think recent events have meant a] greater recognition of the need to have not just members who are passive, who view trade union membership as a service and an insurance, but who see themselves as part of a working class movement which wins through action.”</blockquote>
                                Similarly, Neil from Unite Hospitality explained how:
                                <blockquote>“I think off of the pandemic, there's definitely this attitude of a reckoning amongst wider society. But also definitely within workplaces. You know, you’re seeing workplaces that in much more organised sectors that would normally have accepted less [in negotiations] holding out now.”</blockquote>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>     
                </section>
                <section className="content">
                    <h2>
                        Activism <i>may</i> have more public support than before
                    </h2>
                    <ul className="theme_list">
                        <li>Attitudes to activists’ work fluctuated, as issues rapidly came into the public eye, remained, subsided, or went ignored.</li>
                        <li>Despite this, there was more confidence in public support going forward - including from new cohorts - than from policymakers.</li>
                        <li>The links between support and participation, however, were less clear, varying from activist to activist.</li>
                    </ul>
                    <blockquote>“You can't feed people with claps, claps don’t pay the bills, and now we don't even get that anymore”</blockquote>
                    <p>With the lives of all politicised by the pandemic and a number of widely reported on campaigns, public support was a key talking point for the activists we spoke to. This came through in discussion of how much support fluctuated throughout the pandemic due to different issues coming into the public eye, remaining, subsiding, or going ignored.</p>
                    <p>Despite this variation, there was generally more confidence in public support going forward - including from new cohorts - than support from policymakers. The link between support and active participation, however, was less clear. Some reached out for help from activists who hadn’t before and some got on board initially in response to a campaign who hadn’t before, but it wasn’t clear how long lasting the effect on them had been in terms of their actions.</p>
                    <h2 className="future">
                        What could this mean for the future? A public open to action
                    </h2>
                    <p>Public attitudes towards activists’ work can be hard to predict, and many in civil society will be investigating how public support shifted for them during the pandemic:</p>
                    <ul className="theme_list">
                        <li>If a greater focus on lived experience and community life engendered by the pandemic remains within civil society going forward, organisations may feel increased pressure to change how they involve the public in their actions, from research to advocacy and much else.</li>
                        <li>The growth of interest in public engagement through democratic innovations like citizens assemblies and the pressure for greater devolution amidst the Levelling Up agenda could come into contact with local activism and community organising.</li>
                        <li>Where volunteering, mutual aid and other forms of social action more broadly gained popularity during the pandemic, many in civil society may investigate how this can be tapped into going forward for their work.</li>
                    </ul>
                    <Accordion allowZeroExpanded="true" className="themes-accordion">
                    <AccordionItem>
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                Activism may have more public support than before
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="accordion-content">
                                With the pandemic bringing politics into all our lives in one way or another, and with high-profile events like Christina Adane and Marcus Rashford’s free school meals campaign, the resurgence of Black Lives Matter demonstrations and the Metropolitan Police’s response to the Sarah Everard vigil, determining public support was a major theme for those we spoke to. Some at a community level were very positive about what this period meant for the future. Jake Johnson, from the Manchester branch of ACORN, a mass membership organisation and network of low-income people organising for a fairer deal in their communities, explained:
                                <blockquote>“We've made contacts in the community and the whole model of working is not sort of us going in and being like, “Oh, you need to back us and we'll go and chat to the council and tell them that you back us”. Once you establish contact with people, the next stage is to bring people together, getting them talking to other people in that community who they might not be talking to. Like, facilitate them coming up with a strategy and implementing it themselves. When we do go out and speak to people, we’re met with an enormous amount of sort of enthusiasm for that. So, no, I've got plenty of hope for the future.”</blockquote>
                                Among those in the wider civil society space, there was optimism too, particularly where their campaigning concerns were aligned with new public interests. Robert Palmer, Executive Director of Tax Justice UK, a campaigning and advocacy organisation that works to ensure that everyone in the UK benefits from a fair and effective tax system, highlighted how:
                                <blockquote>“The pandemic has seen the wealth and assets of those with wealth increased fairly substantially, while a lot of people have suffered financially. And so that's been quite a helpful tool for us to frame our arguments for why and how we need to reform the tax system.”</blockquote>
                                Others recognised how, despite the public gaze landing on their cause at certain points, the long-term effects might be greater for those concerned than the wider public. Aidan Harper, an organiser for the Independent Workers’ Union of Great Britain, a grassroots member-led union, described the focus on key workers:
                                <blockquote>“Two positive things happened: people recognised key workers, but [key workers] themselves might also have had a greater sense of their own worth and that was really important, especially when that clashed with the reality of their conditions. [The former recognition] faded away though, I think; you can't feed people with claps, claps don’t pay the bills, and now we don't even get that anymore.”</blockquote>
                                Despite this mixed picture across civil society, there was generally more confidence in public support going forward, including from new cohorts. Alasdair Roxburgh, Director of Communities and Networks at Friends of the Earth during the pandemic and now at Mind, saw the environmental movement becoming a lot more intergenerational than previously. Mattey Mitchell, Health Campaigns Officer at Friends, Families and Travellers (FFT), a national charity that works on behalf of all Gypsies, Travellers and Roma, described the effect the period had had on public perceptions of the communities FFT campaigns on behalf of:
                                <blockquote>“I've seen a switch in the way that the public thinks of it, there's much more public support now. I haven't been used to it to be honest in life; you could kind of guess people aren't going to receive you very well in a room. And I think that's changed. So many people now, just your average Joes, are more aware of these issues now, are more aware of who Roma are, who Travellers are, and the differences between the two, and their issues. I think that's incredible. I think it's really wonderful. So certainly, there's more public support as well.”</blockquote>
                                In some cases the link between support and participation was clear. Sada Abdalla, a community organiser who worked during the pandemic with Racial Justice Network, a West Yorkshire network of individuals, communities and organisations promoting racial justice, highlighted how:
                                <blockquote>“[Before the pandemic] there was a lot of people that, you know, they were hidden people? People who don’t like to come out? So at that time, they wouldn't come. After this pandemic, those people have started contacting me and asked me, “When are you going to do activities, I would love to come to activities.” And actually I opened another group now, which has been going two months. These are people who before they wouldn't come to any activities.”</blockquote>
                                In other cases, the longevity of newfound interest was much harder to track. As Katrina Ffrench, Founder &amp; Director of UNJUST, a non-profit organisation committed to reimagining policing and the criminal justice system, described:
                                <blockquote>“I think the Black Lives Matter stuff invoked or stimulated a lot of activity, and there were people that I suppose were upset, so went and did something which may have counted as activism. But those people weren't the normal people that I saw doing activism. Because I've changed organisations, I don't know if it's fair to say, you know, where all those people are now, because I'm not in as established an organisation and I've also not tried to recruit many people. So my experience of being able to utilise that or assess it is really different from other people's. I will say, that I did notice an increase in people that hadn't traditionally been involved in stuff, but who wanted to show their, you know, their support in terms of anti-racism. Whether they've sustained it once again, I'm not sure.”</blockquote>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </section>
            </ThemeSect>
            <NextSteps className="next">
                <h2>Creating a good future for activism in the UK</h2>
                <Accordion allowZeroExpanded="true" className="next-accordion">
                    <AccordionItem className="next-accordion">
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                What can the media do?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="accordion-content">
                        <ul className="next_list">
                            <li>More outlets should dedicate coverage to activism beyond a focus on high-profile and celebrity campaigns, such as grassroots community organising and industrial action. This would bring experiences and voices into public discourse that too often are overlooked or overshadowed.</li>
                            <li>The stoking of anti-activist sentiment by the media is corrosive to democracy. Whatever people’s opinion on the actions of particular activists, the vilification of dissent in general encourages authoritarian attitudes towards political expression. It also has material costs to those involved, not least by raising the threat of violent reaction.</li>
                        </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="next-accordion">
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                What can funders do?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="accordion-content">
                            <ul className="next_list">
                                <li>The financial and operational impacts of the pandemic, in particular for smaller and newer civil society organisations, has been tough. Funders should recognise their value and listen to how they can be supported.</li>
                                <li>Funders should support smaller civil society organisations with less digital resources to build this capacity. The pandemic has shown how the rapid collaborative benefits of digital technology can be incredibly useful to those in this space.</li>
                                <li>Funders should be wary of evading support for projects that are deemed to be too political or too explicitly activist: this may be some of the most informed work there is out there to support.</li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="next-accordion">
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                What can local authorities and public bodies do?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="accordion-content">
                            <ul className="next_list">
                                <li>Local authorities should provide physical space for community groups to access for their work. This can help promote valuable grassroots organising, interaction between civil society groups, and allows those offering support that requires privacy to be able to carry out this work.</li>
                                <li>Local authorities and other public bodies that were receptive to activist cooperation during the pandemic should maintain these links, or develop them where they do not exist. This could be done where activists are able to mediate with different relevant communities, or where they have infrastructure that can connect people to public services. They should also investigate where digital collaboration can benefit here, for example, to rapidly crowdsource experiences that are relevant to a service, or for quickly sharing information widely. Best practice on these approaches should be shared. Some we spoke to, for example, told us about the comparative ease for the NHS to take this approach during the pandemic thanks to their existing model of patient partnerships.</li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="next-accordion">
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                What can central Government do?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="accordion-content">
                            <ul className="next_list">
                                <li>The Police, Crime, Sentencing and Courts legislation will curtail political expression, worsen distrust felt by many in civil society towards the Government, and ultimately diminish the quality of policymaking. Going forward, policymakers must recognise the valuable insights for policy grassroots activists and civil society as a whole have, remain receptive to this, and protect, rather than constrain, their ability to communicate this.</li>
                                <li>Though the Government has agreed to a review of the new powers relating to protest within two years, this is too long given the potential for harm before then. This review should be brought forward and be carried out independently, rather than through the Home Office as has been agreed.</li>
                                <li>The stoking of anti-activist sentiment by policymakers is corrosive to democracy. Whatever people’s opinion on the actions of particular activists, the vilification of dissent in general encourages authoritarian attitudes towards political expression. It also has material costs to those involved, not least by raising the threat of violent reaction.</li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="next-accordion">
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                What can parliament do?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="accordion-content">
                            <ul className="next_list">
                                <li>More scrutiny is needed going forward by Parliament on how the legislative landscape undermines activism, from protest, to trade union organising, to much else. Opposition parties should work harder to draw attention to where the law needs to change to protect and nurture activism as part of a healthy democratic environment.</li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem className="next-accordion">
                        <AccordionItemHeading className="accordion-title">
                            <AccordionItemButton className='new_button'>
                                What can civil society do?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="accordion-content">
                            <ul className="next_list">
                                <li>The pandemic should be seen by civil society as an opportunity to rethink work cultures that worsen mental health challenges that are endemic to the sector such as burnout. This should also be sector-wide, with activists sharing best practice, as in Anthea Lawson’s recent book <a href="https://www.anthealawson.uk/the-entangled-activist" target="_blank" rel="noreferrer" className="link-hover"><i>The Entangled Activist</i></a>.</li>
                                <li>Where they don’t already, civil society organisations should see themselves as part of broader movements for change and proactively work on coalition building and supporting others in their movement. This includes discouraging zero-sum attitudes between organisations working on the same cause, and recognising the value of digital technology to spread and amplify one another's actions and connect organisations to people at a grassroots level.</li>
                                <li>Civil society organisations should renew attention to understanding the accessibility of their activities, working on hybrid options which balance the advantages of this for those who cannot take part in person, with the importance of in-person connections to create and sustain the social capital that propels movements.</li>
                                <li>Civil society organisations should experiment with the powerful ability of digital methods of crowdsourcing insight. Social media often has a tendency to encourage a focus on individual experience, but the pandemic has been a reminder of its potential for cataloguing this collectively.</li>
                                <li>Civil society organisations should see the shift to a local focus as an opportunity to improve or rethink their representative function: taking inspiration from community organising that gives supporters agency; building listening skills to understand what people need to be able to get involved and what they want from doing so; working more closely with devolved administrations; experimenting with network models of activism; and rethinking how the public are involved in their activities, from research to campaigns and much else.</li>
                                <li>Civil society’s current elevation of lived experience is a step in the right direction, and can help bring perspectives into policy discussions that too often are overlooked. However, this needs to be built on long-term, equal relationships with those concerned, otherwise it can become tokenistic. Here, there is much to be gained by recognising the trust that grassroots community organisers and activists have with people at a local level: this is a valuable connection to both imitate and connect to.</li>                    
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </NextSteps>
        </ContentContainer>
    )
}

const ContentContainer = styled.main`
    text-align: center;
    margin: auto;
    width: 100%;
    height: inherit; // this solved the nested child overflow onto below components!!
    font-size: 20px;
`
const IntroSect = styled.section`
`
const ThemeSect = styled.section`
`
const NextSteps = styled.section`
`

export default Content