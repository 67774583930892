import * as React from "react"
import styled from "styled-components"

import "../css/typography.css"
import "../css/colors.css"

import BreakPoints from "../utils/screens"

import author from "../images/ciaran.jpg"
import twitter from "../images/twitter.svg"
import arrow from "../images/block_arrow.svg"

const Author = () => (
    <CardContainer className="card" id="author">
        <AuthorWrapper>
            <AuthorImage src={author} alt="" />
        </AuthorWrapper>
        <ContentWrapper>
            <CardTitle>
                <b>Ciaran Cummins</b> is a Researcher at Demos.
            </CardTitle>
            <CardSubtitle>
                Get in touch via ciaran.cummins@demos.co.uk
            </CardSubtitle>
            <Links target="_blank" rel="noreferrer" href="https://twitter.com/CiaranCummins1">
                <Socials>
                    <IconWrapper>
                        <img width="20" src={twitter} alt=""/>
                    </IconWrapper>
                    <TwitterHandle>
                        @CiaranCummins1
                    </TwitterHandle>
                </Socials>
            </Links>
        </ContentWrapper>
        <ArrowWrapper>
            <img src={arrow} alt="" />
        </ArrowWrapper>
    </CardContainer>
)

const CardContainer = styled.div`
    position: relative;
    margin: auto;
    padding: 10px;
    max-width: 60%;
    min-height: 299px;
    margin-top: 162px;

    background-color: var(--opaque-teal);

    display: flex;
    align-items: center;
    @media (min-width: 270px) and (max-width: 695px) {
        display: block;
        padding: 20px;
    }

    border-radius: 5px;
    transition: all 0.2s;
`

const AuthorWrapper = styled.div`
    flex: 1;
    text-align: center;
`

const AuthorImage = styled.img`
    border-radius: 50%;
    border: 3px solid rgba(191, 198, 207, 0.18);
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    @media (min-width: 270px) and (max-width: 610px) {
        width: 120px;
    }
    ${BreakPoints.sm} {
        width: 120px;
    }
    ${BreakPoints.md} {
        width: 130px;
    }
    ${BreakPoints.lg} {
        width: 169px;
    }
`

const ContentWrapper = styled.div`
    flex: 1;
    position: relative;

    @media (min-width: 270px) and (max-width: 695px) {
        text-align: center;
    }
    @media (min-width: 695px) and (max-width: 768px) {
        margin-right: 20px;
        margin-bottom: 30px;
    }    
    ${BreakPoints.md} {
        margin-right: 60px;
        margin-bottom: 30px;
    }
    ${BreakPoints.lg} {
        margin-right: 90px;
        margin-bottom: 30px;
    }
`

const CardTitle = styled.h4`
    font-family: "Medium";
    font-weight: 500;
    font-size: var(--fs-800);
    line-height: 42px;
    @media (min-width: 270px) and (max-width: 373px) {
        font-size: var(--fs-700);
    }
    ${BreakPoints.md} {
        font-size: var(--fs-800);
    }
`

const CardSubtitle = styled.h5`
    font-family: Light;
    font-weight: 500;
    font-size: var(--fs-700);
    line-height: 34px;
    /* opacity: 0.5; */
    color: black;
    @media (min-width: 270px) and (max-width: 373px) {
        font-size: var(--fs-600);
        word-break: break-all;
    }
    ${BreakPoints.md} {
        font-size: var(--fs-700);
    }
`

const Socials = styled.div`
    display: flex;
    @media (min-width: 270px) and (max-width: 500px) {
        margin-left: 30%;
    }
    @media (min-width: 500px) and (max-width: 694px) {
        margin-left: 40%;
    }
`

const Links = styled.a`
`

const IconWrapper = styled.div``

const TwitterHandle = styled.div`
    left: 30px;
    top: 2px;
    text-decoration: none;
    font-family: 'Medium';
    font-size: var(--fs-600);
    @media (min-width: 270px) and (max-width: 373px) {
        font-size: var(--fs-500);
    }
    ${BreakPoints.md} {
        font-size: var(--fs-600);
    }
`
const ArrowWrapper = styled.div`
    position: absolute;
    top: -20px;
    right: -20px;
`

export default Author