import * as React from "react"
import styled from "styled-components"

import "../css/colors.css"
import "../css/typography.css"
import "../css/fonts.css"

import BreakPoints from "../utils/screens"

import demos_logo from "../images/logo_white.png"

const Footer = () => (
    <SiteFooter>
        <ContentWrapper>
            <a target="_blank" rel="noreferrer" href="https://demos.co.uk/">
                <DemosLogo alt="Demos Logo" src={demos_logo} />
            </a>
            <ContentMenu>
                <li>
                    © {new Date().getFullYear()}, {`Demos`}
                </li>
                <li>
                    <a href="https://demos.co.uk/privacy-policy/" target="_blank" rel="noreferrer">
                        Privacy Policy
                    </a>
                </li>
            </ContentMenu>
        </ContentWrapper>
    </SiteFooter>
)

const SiteFooter = styled.footer`
    background-color: var(--demos-dark);
    left: 0;
    right: 0;    
    bottom: 0;
    height: auto;
    display: flex;
    justify-content: center;
    > .content {
        padding: 40px 0;
    }
    /* width: 100vw; */ // removing stopped footer overflow in x axis
    /* min-height: 176px; */ // removing this solved height pushing up
`

const ContentWrapper = styled.div`
    padding: 30px;
    text-align: center;
`

const ContentMenu = styled.ul`
    color: var(--color-white);
    list-style: none;
    margin-left: 0;
    padding-top: 10px;
    padding-left: 0;
    margin-bottom: 5px;
    font-size: var(--fs-600);
    font-family: Roman;
    > li > a {
        color: var(--color-white);
    }
    > li {
        display: inline-block;
        margin: 0 10px;
        line-height: 20px;
    }
    @media only screen and (max-width: 992px){
    > li {
        display: block;
        margin: 12px 0;
        }
    }
`

const DemosLogo = styled.img`
    width: 110px;
    height: auto;
    @media (min-width: 270px) and (max-width: 610px) {
        width: 110px;
        height: auto;
    }
    ${BreakPoints.sm} {
        width: 110px;
        height: auto;
    }
    ${BreakPoints.md} {
        width: 200px;
        height: auto;
    }
    ${BreakPoints.lg} {
        width: 200px;
        height: auto;
    }
`

export default Footer