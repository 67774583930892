import * as React from "react"
import styled from "styled-components"

import "../css/colors.css"
import "../css/typography.css"
import "../css/fonts.css"

import BreakPoints from "../utils/screens"

const Credits = () => (
    <CreditsContainer>
        <TitleWrapper>
            <CreditsTitle>
                Special thanks
            </CreditsTitle>
        </TitleWrapper>

        <ContentWrapper>
            <CreditsContent>
                To Molly Corlett for her advice, and to Demos 
                colleagues for all their input, particularly Akshaya Satheesh for 
                creating this beautiful site. We would like to thank all those 
                who offered their time and insights for this research: 
                Aidan Harper (Organiser, Independent Workers’ Union of Great Britain), 
                Alasdair Roxburgh (former Director of Communities and Networks, Friends of the Earth), 
                Hannah Dewhirst (Campaigner, EveryDoctor), Jake Johnson (ACORN, Manchester), 
                Katrina Ffrench (Founder &amp; Director UNJUST), Matt Clifton (Chief Executive, bemix), 
                Mattey Mitchell (Health Campaign Officer, Friends, Families and Travellers), 
                Nathan Oswin (Covid-19 Bereaved Families for Justice), Neil Moore (Organiser, 
                Unite Hospitality), Ondine Sherwood (Co-founder, LongCovidSOS), Robert Palmer 
                (Executive Director, Tax Justice UK), Sada Abdalla (Racial Justice Network), 
                Shiri Shalmy (Co-founder, Cooperation Town), Tracey Herrington (Manager, Thrive 
                Teeside), Anthea Lawson (campaigner and author) and Wolfgang Wopperer-Beholz (Extinction Rebellion).  
            </CreditsContent>
        </ContentWrapper>
    </CreditsContainer>
)

const CreditsContainer = styled.section`
    background-color: var(--opaque-grey);
    display: flex column;
    @media (min-width: 270px) and (max-width: 610px) {
        padding: 20px;
        border: none;
        margin-top: 152px;
    }
    ${BreakPoints.sm} {
        border: none;
        padding: 30px;
        margin-top: 152px;
    }
    ${BreakPoints.md} {
        border: none;
        padding: 55px;
        margin-top: 162px;
    }
    position: relative;
`

const TitleWrapper = styled.div`
    flex: 50%;
    text-align: center;
`

const CreditsTitle = styled.h4`
    font-family: HeavyOB;
    text-transform: uppercase;
    font-size: var(--fs-900);
    line-height: 41px;
    @media (min-width: 270px) and (max-width: 610px) {
        font-size: var(--fs-800);
    }
`

const ContentWrapper = styled.div`
    margin: auto;
    width: 70%;
`

const CreditsContent = styled.p`
    font-family: Roman;
    font-size: var(--fs-600);
    line-height: 31px;
    text-align: justify;
    @media (min-width: 270px) and (max-width: 610px) {
        font-size: var(--fs-700);
    }
`

export default Credits