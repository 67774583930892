import * as React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

import "../css/typography.css"
import "../css/colors.css"
import BreakPoints from "../utils/screens"

import demos_logo from "../images/logo_white.png"
import header from "../images/header.png"

const scrollToRead = () => {
  window.scrollTo({
    top: 1020,
    behavior: 'smooth'
  });
};

const Header = () => (
    <HeroContainer className="hero">

      <HeroWrapper>
        <ImageWrapper
          style={{backgroundImage: `url(${header})`}}             
          alt="A crowd of protestors"
          className="overlay"
        />
        <LogoWrapper>
          <a target="_blank" rel="noreferrer" href="https://demos.co.uk/">
            <img alt="Demos Logo" width="200" height="auto" src={demos_logo} />
          </a>
        </LogoWrapper>
        <HeadingWrapper>
          <HeadingText>
            Post-Pandemic 
            <br/>
            People Power
          </HeadingText>
          <SummaryText>
            Demos has listened to the experiences of activists during the pandemic 
            to understand what they might tell us about the future of politics. 
            After a decade defined by fears of populism, democracy’s erosion, and
            growing division, our conversations present a different picture going 
            forward. As more turn from despair to defiance and are driven to take 
            action to care for others, distinctions between ‘fringe’ activists and 
            the public will become harder to draw. This future, in which we are all 
            activists at times, demands that activism be nurtured, protected, and 
            improved, so that it can continue to be a vital engine for upholding 
            and furthering democracy.
          </SummaryText>
        <FontAwesomeIcon className="arrowDown" icon={faArrowDown} onClick={scrollToRead} />
        </HeadingWrapper>
      </HeroWrapper>
    </HeroContainer>
)

const HeroContainer = styled.header`
`

const HeroWrapper = styled.div`
    height: 126vh;
    min-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

const ImageWrapper = styled.div`
  position: absolute;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
`

const HeadingWrapper = styled.div`
  position: absolute;
  text-align: center;
  @media (min-width: 260px) and (max-width: 350px) {
    top: 140px;
  }   
  ${BreakPoints.lg} {
    top: 150px;
  }
`

const HeadingText = styled.h1`
  font-family: "Black";
  color: var(--color-white);
  font-size: var(--fs-900);
  @media (min-width: 270px) and (max-width: 350px) {
    font-size: var(--fs-ml);
    line-height: 46px;
  }   
  @media (min-width: 350px) and (max-width: 459px) {
    font-size: var(--fs-l);
    line-height: 46px;
  }   
  @media (min-width: 460px) {
    font-size: var(--fs-l);
    line-height: 46px;
  }   
  ${BreakPoints.lg} {
    font-size: var(--fs-xl);
    line-height: 86px;
  }
`

const SummaryText = styled.h2`
  color: var(--color-white);
  font-family: "Medium";
  font-weight: 500;
  font-size: var(--fs-700);
  @media (min-width: 260px) and (max-width: 351px) {
    font-size: var(--fs-500);
    line-height: 20px;
    margin: 20px;  
    text-align: justify;
  }   
  ${BreakPoints.sm} {
    line-height: 20px;
    font-size: var(--fs-700);
    text-align: justify;
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
  }
  ${BreakPoints.md} {
    line-height: 34px;
    font-size: var(--fs-700);
    text-align: justify;
  }
  ${BreakPoints.lg} {
    line-height: 34px;
    font-size: var(--fs-700);
    text-align: justify;
  }
`

export default Header