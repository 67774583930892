import * as React from "react"
import CookieConsent from 'react-cookie-consent';

import Header from "./header"
import Footer from "./footer"
import Content from "../components/content"
import Author from "../components/author"
import Credits from "../components/credits"

import "../css/layout.css"
import "../css/typography.css";
import "../css/colors.css";

function Layout(){
  return(
    <>
      <Header/>
      <Content/>
      <Author />
      <Credits />
      <Footer />
      <CookieConsent
        ariaAcceptLabel	
        ariaDeclineLabel	
        style={{backgroundColor: "#8ea1a7", justifyContent: 'center'}}
        buttonStyle={{ fontWeight:"bold", color: "var(--demos-dark)", backgroundColor: "white" }}
        declineButtonStyle={{ fontWeight:"bold", color: "var(--demos-dark)", backgroundColor: "white" }}
        contentStyle={{color: "white", fontFamily: "Avenir", fontSize: "var(--fs-500)"}}
        enableDeclineButton
        flipButtons
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics">
      This website uses Google Analytics to help us analyse traffic. If you'd like to opt out of this, click "Decline".
      </CookieConsent>
    </>
  )
}


export default Layout